window.angular.module('MyHippoProducer.Components').directive('toggle', function () {
    return {
        restrict: 'E',
        scope: { isToggled: '=', disabled: '<' },
        templateUrl: 'components/toggle/toggle.html',
        controller: function ($scope) {
            $scope.toggle = function () { $scope.isToggled = !$scope.isToggled; };
        }
    };
});
